import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/layout/Layout";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: "poslogin",
        component: () => import(/* webpackChunkName: "payment-view" */ '../views/PosLoginView.vue')
    },

    {

        path: '/',
        component: Layout,
        children: [
            {
                path: '/dashboard',
                name: "dashboard",
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/boffice/home/HomeView'),
            },

            {
                path: '/stock-take',
                name: "stock-take",
                component: () => import(/* webpackChunkName: "stock-take" */ '../views/boffice/StockTake/StockTakeListView'),
            },

            {
                path: '/stock-adjustment',
                name: "stock-adjustment",
                component: () => import(/* webpackChunkName: "stock-adjustment" */ '../views/boffice/StockAdjustment/StockAdjustmentView'),
            },

            {
                path: '/create-stock-take',
                name: "create-stock-take",
                component: () => import(/* webpackChunkName: "create-stock-take" */ '../views/boffice/StockTake/CreateListView'),
            },

            {
                path: '/run-stock-take',
                name: "run-stock-take",
                component: () => import(/* webpackChunkName: "run-stock-take" */ '../views/boffice/StockTake/StockTakeItemsView'),
            },

            {
                path: '/settings',
                name: "settings",
                component: () => import(/* webpackChunkName: "settings" */ '../views/boffice/settings/SettingsView'),
            },

            {
                path: '/select-supplier',
                name: "select-supplier",
                component: () => import(/* webpackChunkName: "select-supplier" */ '../views/boffice/stock-receive/SupplierSelectView'),
            },

            {
                path: '/select-received-items',
                name: "select-received-items",
                component: () => import(/* webpackChunkName: "select-received-items" */ '../views/boffice/stock-receive/SelectItemsReceived'),
            },
        ]
    },

    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login"*/ '../views/LoginView.vue'),
    },
    {
        path: '/setup',
        name: 'setup',
        component: () => import(/* webpackChunkName: "setup"*/ '../views/setup/AppSetupLogin.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'login') {
        auth.isAdminLoggedIn().then((res) => {
            if (!res) next({name: 'login'})
            else next()
        })
    } else {
        next()
    }
})

export default router
