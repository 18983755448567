import {Storage} from "@/services/Storage";
import axios from "axios";
import {responses} from "@/utils/responses";

export default {
    name: 'user',
    state: {
        currentUser: undefined,
        users: [],
    },
    getters: {
        getCurrentUser: (state) => state.currentUser,

        getUsers: (state) => state.users,
        getUserByPass: (state) => (password) => {
            return Storage.getAllUsers().then(responses => {
                state.users = responses
                return responses;
            }).then(() => {
                return state.users.find(u => u.Password.toLowerCase() === password.toLowerCase());
            })
        },

        getUserByID: (state) => (id) => {
            return Storage.getAllUsers().then(responses => {
                state.users = responses
                return responses;
            }).then(() => {
                return state.users.find(u => u.id === parseFloat(id));
            })
        },
    },
    mutations: {
        setCurrentUser: (state, data) => {
            if (data) {
                state.currentUser = data
            } else {
                return Storage.getCurrentUser().then(user => {
                    state.currentUser = user;
                })
            }
        },
        setAllUsers: async (state, data) => {
            if (data){
                await Storage.setAllUsers(data)
            }else {
                data = Storage.getAllUsers();
            }
            state.users = data
        },
    },
    actions: {
        getAllUsers: (context, payload) => {
            return axios.get(franchiseApi + `Employee/getAll/${payload.StoreDB}?limit=-1`)
                .then(({data}) => {
                    context.commit('setAllUsers', data);
                    return data;
                }).catch(({response}) => {
                    let message = response.data.message?response.data.message:response.data.split('\n', 1)[0];
                    responses.throwErr(message)
                    throw new Error(message)
                })
        },

        posLogin: (context, data) => {
            let currentUser = context.getters.getUsers
                .find(user => user.Password.toLowerCase() === data.toLowerCase());
            if (currentUser) {
                Storage.setCurrentUser(currentUser).catch(err => console.err(err));
                context.commit(`setCurrentUser`, currentUser);
                return true;
            }
            return false;
        },
        editEmployee(context, payload) {
            let store = context.getters.getDefaultStore;
            return axios.post(franchiseApi + `updateEmp/${store.StoreDB}`, payload).then(({data}) => {
                Storage.getAllUsers().then(res => {
                    res = res.map(user => {
                        if (user.id === data.id) {
                            return data
                        }
                        return user;
                    });
                    Storage.setAllUsers(res).then(() => {
                        context.commit('setAllUsers', res)
                    }).catch(err => responses.throwErr(err));
                })
            }).catch(({response}) => {
                let message = response.data.message?response.data.message:response.data.split('\n', 1)[0];
                responses.throwErr(message)
                throw new Error(message)
            })
        },

        createEmployee(context, payload) {
            let store = context.getters.getDefaultStore;
            return axios.post(franchiseApi + `Employee/${store.StoreDB}`, payload).then(({data}) => {
                Storage.getAllUsers().then(res => {
                    res.push(data);
                    Storage.setAllUsers(res).then(() => context.commit('setAllUsers', res))
                })
            }).catch(({response}) => {
                let message = response.data.message?response.data.message:response.data.split('\n', 1)[0];
                responses.throwErr(message)
                throw new Error(message)
            })
        },
    }
}
