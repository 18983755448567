import axios from "axios"
import {Storage} from "@/services/Storage";
import {responses} from "@/utils/responses";

export default {
    name: 'supplier',
    state: {
        suppliers : [],
        selectedSupplierCode : ""
    },
    getters: {
        getAllSuppliers: (state) => state.suppliers,
        getSelectedSupplierCode : (state) => state.selectedSupplierCode
    },

    mutations: {
        setSuppliers: (state, data) => state.suppliers = data,
        setSelectedSupplierCode : (state, data) => state.selectedSupplierCode = data
    },
    actions: {
        getAllSuppliers: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi+`Supplier/getAll/${store.StoreDB}`)
                .then(({data})=> context.commit('setSuppliers', data))
                .catch(({response}) => {
                    let message = response.data.message?response.data.message:response.data.split('\n', 1)[0];
                    responses.throwErr(message);
                    throw new Error(message)
                })
        }
    }
}
