import {Device as Gadget} from '@capacitor/device';

export const Device = {
    logDeviceInfo: async () => {
        const info = await Gadget.getInfo();
        console.log(info);
    },
    getDeviceId: async () => {
        return await Gadget.getId();
    },
    logDeviceBatteryInfo: async () => {
        const info = await Gadget.getBatteryInfo();
        console.log(info);
    }
}
