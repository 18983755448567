import axios from "axios"
import {Storage} from "@/services/Storage";
import {responses} from "@/utils/responses";

export default {
    name: 'stockTake',
    state: {
        stockTakeList: [],
        stockTakeItems: [],
        stockTakeRoute: '/stock-take',
    },
    getters: {
        getStockTakeList: (state) => state.stockTakeList,
        getStockTakeRoute: (state) => state.stockTakeRoute,
        getStockTakeItems: (state) => state.stockTakeItems,
    },

    mutations: {
        setStockTakeList: (state, data) => state.stockTakeList = data,
        setStockTakeRoute: (state, data) => state.stockTakeRoute = data,
        setStockTakeItems: async (state, data) => {
            let finalSet = [];
            let allStock = await Storage.getAllStock()
            for (let i = 0; i < data.length; i++) {
                let stockItem = allStock.find(item => item.PLU === data[i]);
                if (stockItem) {
                    stockItem.countedQty = '';
                    finalSet.push(stockItem);
                }
            }
            state.stockTakeItems = finalSet;
        },
    },
    actions: {
        async getStockTakeList(context) {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi + `SavedStockTakes/getAll/${store.StoreDB}`)
                .then(({data}) => context.commit('setStockTakeList', data))
                .catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    responses.throwErr(message)
                    throw new Error(message)
                })
        },

        async getStockTakeItemsList(context, id) {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi + `StockTakeSavedList/getPluByID/${id}/${store.StoreDB}`)
                .then(({data}) => context.commit('setStockTakeItems', data))
                .catch(({message}) => responses.throwErr(message)).catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    responses.throwErr(message)
                    throw new Error(message)
                })
        },

        async finalizeStockTake(context,location) {
            let store = await Storage.getDefaultStore();
            let items = context.getters.getStockTakeItems
                .filter(item=> item.countedQty !== '')
                .map(item => {
                    return {
                        'plu': item.PLU,
                        'counted': parseFloat(item.countedQty),
                    }
                });
            let data = {
                user: context.getters.getCurrentUser.Username,
                location :location,
                PC: 'mobile',
                items: items
            };
            return axios.post(serverApi + `stocktake/${store.StoreDB}`, data)
                .then(() => {
                    context.commit('setStockTakeItems', []);
                    context.commit('setStockTakeRoute', '/stock-take');
                    context.dispatch('getAllStock')
                }).catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    throw new Error(message)
                })
        },
        async createStockTakeList(context, data) {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseApi + `SavedStockTakes/${store.StoreDB}`, data)
                .then(() => true)
                .catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    throw new Error(message)
                })
        }
    }
}
