import Vue from 'vue'
import Vuex from 'vuex'
import stockTake from '@/store/modules/stock-take-module'
import stock_module from "@/store/modules/stockmodule";
import user_module from "@/store/modules/user_module";
import stockCategory from "@/store/modules/stockcategory-module";
import supplier from "@/store/modules/supplier-module";
import stockReceived from "@/store/modules/stock-received-module"
import stockLocation from "@/store/modules/stock-location-module"
import axios from "axios";
import {Storage} from "@/services/Storage"
import {responses} from "@/utils/responses";

Vue.use(Vuex)
let CurrentTime = function () {
    let date = new Date();
    let d = date.toISOString().split('T')[0]
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let t = hour + ':' + minute + 'hrs';
    return d + ` at ` + t;
}

export default new Vuex.Store({
    state: {
        registeredStores: [],
        defaultStore: {},
        lastUpdated: '',
        safes : []
    },
    getters: {
        getRegisteredStores: (state) => state.registeredStores,
        getDefaultStore: (state) => state.defaultStore,
        getLasUpdated: (state) => state.lastUpdated,
        getAllSafes: (state) =>  state.safes
    },
    mutations: {
        setRegisteredStores: (state, data) => {
            state.registeredStores = data
            Storage.setRegisteredStores(data).catch(err => console.error(err))
        },
        setLastUpdated: (state, payload) => state.lastUpdated = payload,
        toggleNavigation: (state, width) => state.navigation = width,
        setDefaultStore: (state, data) => {
            state.defaultStore = data
            return Storage.setDefaultStore(data);
        },

        setAllSafes: (state, data) => {
            state.safes = data
            return Storage.setSafes(data);
        },
    },
    actions: {
        setDefaultStore: (context, data) => {
            context.commit('setDefaultStore', data)
            return context.dispatch('syncData')
                .catch(err => {
                    throw new Error(err)
                })
        },
        syncData: async ({dispatch, commit}) => {
            let value = await Storage.getDefaultStore();
            if (!value) {
                return false;
            }
            return axios.all([
                dispatch('getCategories'),
                dispatch('getAllStock'),
                dispatch('getAllStockLocations'),
                dispatch('getAllUsers', value)
            ]).then(() => {
                commit("setCurrentUser");
                commit('setLastUpdated', CurrentTime())
            });

        },
        appLogin: (context, data) => {
            return axios.post(franchiseApi + 'user/newlogin', data)
                .then(res => {
                    context.commit('setRegisteredStores', res.data)
                    return data;
                }).then(data => Storage.setAdminUser(data).catch(err => console.log(err)))
                .catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    responses.throwErr(message);
                    throw new Error(message)
                })
        },
        getDashBoardData: ({dispatch}) => {
            return axios.all([
                dispatch('getStockValue'),
                dispatch('getBelowMin'),
                dispatch('getReceivedRecently'),
            ]);
        },
        getAllSafes: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi+`finance/AllSafeBal/${store.StoreDB}`)
                .then(({data})=>context.commit('setAllSafes',data.All))
                .catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    responses.throwErr(message);
                    throw new Error(message)
                })
        },
    },
    modules: {stockTake, stock_module, user_module, stockCategory, supplier, stockReceived,stockLocation}
})
