import {Storage} from "@/services/Storage"
import store from '../store'

export const authentication = {
    isCashierLoggedIn() {
        return Storage.getCurrentUser().then(cashier => {
            return this.hasUsers().then(() => {
                if (!!cashier) {
                    store.commit('setCurrentUser', cashier);
                }
                return !!cashier;
            })
        }).catch(() => false)
    },

    hasUsers() {
        return Storage.getAllUsers().then(users => {
            if (!!users) {
                store.commit('setAllUsers', users);
            }
            return !!users;
        }).catch(() => false);
    },

    isAdminLoggedIn: () => {
        return Storage.getAdminUser().then((adminUser) => {
            if (!!adminUser) {
                Storage.getRegisteredStores().then((registeredStores) => {
                    store.commit('setRegisteredStores', registeredStores)
                })
            }
            return !!adminUser
        }).catch(() => false)
    },

    isStoreSelected() {
        return Storage.getDefaultStore().then((defaultStore) => {
            if (defaultStore) {
                store.commit('setDefaultStore', defaultStore)
            }
            return !!defaultStore;
        }).catch(() => false)
    },

    isFloatSet(user) {
        return Storage.getFloat().then(res => {
            let userFloat = res.find(f => f.user.toLowerCase() === user.toLowerCase());
            return !!userFloat
        }).catch(() => false)
    }
}
