import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHouse, faBarcode, faMagnifyingGlass, faPlus, faMinus, faBars, faAngleRight, faArrowLeft, faDeleteLeft,
    faCartShopping, faPaperPlane, faClose, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,
    faReceipt, faGear, faXmark, faChevronRight, faArrowRightFromBracket, faCreditCard, faMoneyBill, faChevronUp,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faUserPlus, faHandHoldingDollar, faCoins, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave,faChartLine,faUpload,faSliders,faHandHoldingDroplet
} from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faHouse, faBarcode, faMagnifyingGlass, faClose, faPlus, faMinus, faBars, faAngleRight, faArrowLeft,
    faDeleteLeft, faCartShopping, faPaperPlane, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,
    faReceipt, faGear, faArrowRightFromBracket, faXmark, faChevronRight, faCreditCard, faMoneyBill, faChevronUp,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave, faArrowsRotate, faLayerGroup,
    faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus,faChartLine,faUpload,faSliders,faHandHoldingDroplet)
