import {Storage as Store} from '@capacitor/storage';

export const REGISTERED_STORES = 'registered_stores'
export const DEFAULT_STORE = 'default_store'
export const ADMIN = 'admin'
export const CATEGORIES = 'categories'
export const AllUsers = 'all_users'
export const CurrentUser = 'current_user'
export const STOCK = 'stock'
export const STOCK_SETTING = 'stock_setting'
export const SAFES = 'safes'
export const LOCATIONS = 'locations'

export const FLOAT = 'float'
export const DEVICE_SETTINGS = 'device_settings'
export const SAVED_TABLES = 'saved_tables'
const notEmpty = (val) => {
    if (!val) return false
    return val !== ''
}
const encryptAdmin = (val) => {
    // return CryptoJS.AES.encrypt(val, 'Admin-User-Hello').toString()
    return val;
}
const decryptAdmin = (val) => {
    // return CryptoJS.AES.decrypt(val, 'Admin-User-Hello').toString(CryptoJS.enc.Utf8)
    return JSON.parse(val);
}
export const Storage = {
    setAdminUser: async (user) => {
        await Store.set({
            key: ADMIN, value: encryptAdmin(JSON.stringify(user))
        })
    }, getAdminUser: async () => {
        const value = await Store.get({
            key: ADMIN
        })
        return decryptAdmin(value.value)
    }, setRegisteredStores: async (stores) => {
        await Store.set({
            key: REGISTERED_STORES, value: JSON.stringify(stores)
        })
    }, setCategories: async (categories) => {
        await Store.set({
            key: CATEGORIES, value: JSON.stringify(categories)
        })
    },
    getCategories: async () => {
        const value = await Store.get({
            key: CATEGORIES
        })
        return JSON.parse(value.value)
    }, getRegisteredStores: async () => {
        const value = await Store.get({
            key: REGISTERED_STORES
        })
        return JSON.parse(value.value)
    }, setDefaultStore: async (defaultStore) => {
        await Store.set({
            key: DEFAULT_STORE, value: JSON.stringify(defaultStore)
        })
    },
    getDefaultStore: async () => {
        const value = await Store.get({
            key: DEFAULT_STORE
        })
        return JSON.parse(value.value)
    },
    setAllUsers: async (users) => {

        await Store.set({
            key: AllUsers, value: JSON.stringify(users)
        })
    },
    getAllUsers: async () => {
        const value = await Store.get({
            key: AllUsers
        });
        return JSON.parse(value.value);
    }, setCurrentUser: async (currentUser) => {
        await Store.set({
            key: CurrentUser, value: JSON.stringify(currentUser)
        })
    }, getCurrentUser: async () => {
        const value = await Store.get({
            key: CurrentUser
        })
        return JSON.parse(value.value)
    }, setStock: async (stock) => {
        await Store.set({
            key: STOCK, value: JSON.stringify(stock)
        });
    },
    getAllStock: async () => {
        const value = await Store.get({
            key: STOCK
        });
        return JSON.parse(value.value);
    },
    getByPlu: async (plu) =>{
      const value = await Store.get({
          key : STOCK
      });
      let stock = JSON.parse(value.value) ?? [];
      return stock.find(item => item.PLU === plu)
    },
    getFastLine: async () => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value) ?? [];
        return stock.filter(sc => sc.FastLine === 1);
    },
    searchStockDesc: async (query) => {
        if (notEmpty(query.category)) {
            return (await Storage.searchStock(query))
                .filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                    || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                    || s.barcode.toLowerCase().includes(query.description.toLowerCase())
                )
        }
        return (await Storage.getAllStock())
            .filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                || s.barcode.toLowerCase().includes(query.description.toLowerCase())
            )

    },
    searchStock: async (query) => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value) ?? [];
        let result = [];
        if (query.category !== '' && query.subCategory !== '' && query.sub2Category !== '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase())
                .filter(s2 => s2.SubDep.toLowerCase() === query.subCategory.toLowerCase())
                .filter(s3 => s3.Subdep2.toLowerCase() === query.sub2Category.toLowerCase());
        } else if (query.category !== '' && query.subCategory !== '' && query.sub2Category === '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase())
                .filter(s2 => s2.SubDep.toLowerCase() === query.subCategory.toLowerCase())
        } else if (query.category !== '' && query.sub2Category === '' && query.subCategory === '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase());
        }
        if (query.description) {
            return result.filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                || s.barcode.toLowerCase().includes(query.description.toLowerCase())
            )
        }
        return result;
    },
    editStockItem: async payload => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value);
        stock = stock.map(s => {
            if (s.PLU === payload.PLU) {
                return payload
            }
            return s;
        })
        await Storage.setStock(stock);
    },
    setFloat: async (payload) => {
        await Store.set({
            key: FLOAT, value: JSON.stringify(payload)
        })
    }, getFloat: async () => {
        const value = await Store.get({
            key: FLOAT
        })
        return JSON.parse(value.value);
    },
    setDeviceSettings : async (payload) => {
      await Store.set({
          key : DEVICE_SETTINGS,
          value : JSON.stringify(payload)
      })
    },

    getDeviceSettings: async () => {
      const value = await Store.get({
          key : DEVICE_SETTINGS
      });
      return JSON.parse(value.value);
    },

    setSafes : async (payload) => {
      await Store.set({
          key : SAFES,
          value : JSON.stringify(payload)
      })
    },

    getAllSafes: async () => {
      const value = await Store.get({
          key : SAFES
      });
      return JSON.parse(value.value);
    },

    getAllTables : async () => {
      const value = await Store.get({
          key : SAVED_TABLES
      });
      return JSON.parse(value.value);
    },

    setTables : async (payload) => {
        await Store.set({
           key : SAVED_TABLES,
           value : JSON.stringify(payload)
        });
    },

    createTable : async (payload) => {
        let value = await Store.get({
            key: SAVED_TABLES
        });
        if(!value){
            value = {
                value : JSON.stringify([]),
            }
        }
        let tables = JSON.parse(value.value);
        if (!tables){
            tables = []
        }
        tables.push(payload)
        await Store.set({
            key : SAVED_TABLES,
            value : JSON.stringify(tables)
        });
    },

    editTable : async (payload) => {
        const value = await Store.get({
            key: SAVED_TABLES
        });
        let tables = JSON.parse(value.value);
        tables = tables.map(table =>{
            if (table.SaleNum === payload.SaleNum) {
                return payload
            }
            return table;
        })
        await Store.set({
            key : SAVED_TABLES,
            value : JSON.stringify(tables)
        });
    },

    deleteTable : async (salenum) => {
        const value = await Store.get({
            key: SAVED_TABLES
        });
        let tables = JSON.parse(value.value);
        tables = tables.filter(table => table.SaleNum !== salenum);
        await Store.set({
            key : SAVED_TABLES,
            value : JSON.stringify(tables)
        });
    },

    clear: async () => Store.clear(),
    setStockSettings: async (stock) => {
        await Store.set({
            key: STOCK_SETTING, value: JSON.stringify(stock)
        });
    },
    getStockSetting: async (plu) => {
        const value = await Store.get({
            key: STOCK_SETTING
        });
        const settings =JSON.parse(value.value)
        return settings.find(set=>set.plu===plu);
    },

    setLocations : async (payload) => {
        await Store.set({
            key : LOCATIONS,
            value : JSON.stringify(payload)
        })
    },

    getAllLocations: async () => {
        const value = await Store.get({
            key : LOCATIONS
        });
        return JSON.parse(value.value);
    },
}
