import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'stock-location',
    state: {
        allLocations : []
    },
    getters: {
        getAllLocations: (state) => state.allLocations,
    },

    mutations: {
        setCurrentLocation: (state,data) => state.currentLocation = data,
        setStockLocations : async (state, data) => {
            if (!data) {
                data = await Storage.getAllLocations();
            }else {
                await Storage.setLocations(data);
            }
            state.allLocations = data;
        }
    }
    ,
    actions: {
        getAllStockLocations: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi+`StockStation/getAll/${store.StoreDB}`)
                .then(({data})=>context.commit(`setStockLocations`,data))
        },

        getItemsByLocations: async (context,location) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi+`StockStation/getItemsByLocation/${store.StoreDB}`,{
              params : { location : location }
            }).then(({data})=>context.commit(`setStockTakeItems`,data))
        },


    }
}
