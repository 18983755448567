<template>
  <vue-drawer-layout
      ref="drawer"
      :drawer-width="300"
      @mask-click="closeDrawer">
    <div slot="drawer" v-if="!getSaleUnits" >
      <NavigationDrawer :close="closeDrawer" class="navigation-drawer"/>
    </div>
    <div slot="content" class="root-pane">
      <NavigationBar :toggle="openDrawer" class="nav"/>
      <div class="navigation-route-view">
        <transition-page>
          <router-view/>
        </transition-page>
      </div>
    </div>
  </vue-drawer-layout>
</template>

<script>
import NavigationBar from "@/components/layout/navigation/NavigationBar";
import NavigationDrawer from "@/components/layout/navigation/NavigationDrawer";
import TransitionPage from "@/components/layout/animations/TransitionPage";

export default {
  name: "Layout",
  computed: {
    sideBarWidth() {
      return this.$store.getters.navigation;
    },
    getSaleUnits() {
      return this.$store.getters.getSaleUnits
    },
  },
  components: {TransitionPage, NavigationDrawer, NavigationBar},
  methods: {
    openDrawer() {
      if (!this.getSaleUnits) {
        this.$refs.drawer.toggle();
      }
    }, closeDrawer() {
      this.$refs.drawer.toggle(false);
    }
  }

}
</script>

<style lang="scss" scoped>
.root-pane {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.navigation-drawer {
  min-width: 285px;
  width: 100%;
  height: 100vh;
}

.navigation-route-view {
  transition: 0.5s ease;
}
</style>
