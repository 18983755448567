<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  beforeMount() {
    if (this.$route.path !== '/login') {
      this.$store.dispatch('syncData')
    }
  },
  created() {
    if (this.$route.path !== '/login') {
      return this.$nextTick(() => {
        window.setInterval(() => this.$store.dispatch('syncData'),
            600000);
      })
    }
  }
}
</script>
<style lang="scss">
@import "assets/css/styles";
</style>
