export const utils = {
    toPascalCase(string) {
        return `${string.toLowerCase()}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w*)/, 'g'),
                ($1, $2, $3) => ` ${$2.toUpperCase() + $3.toLowerCase()}`
            )
            .replace(new RegExp(/\w/), s => s.toUpperCase());
    },
    cutLength(string, limit = 45) {
        if (string.length < limit) {
            return string
        } else {
            return string.substring(0, limit).concat('...')
        }
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",", symbol = "K") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(
                (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
            ).toString();
            let j = i.length > 3 ? i.length % 3 : 0;

            return (
                `${symbol} ` +
                negativeSign +
                (j ? i.substr(0, j) + thousands : "") +
                i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
                (decimalCount
                    ? decimal +
                    Math.abs(amount - i)
                        .toFixed(decimalCount)
                        .slice(2)
                    : "")
            );
        } catch (e) {
            console.error(e);
        }
    },
    removeIf(arr, predicate) {
        return arr.filter(predicate);
    },
    remove(arr, obj) {
        return arr.filter(v => v !== obj)
    }
}
