<template>
  <div class="drawer" v-if="curUser">
    <div class="user-avatar">
      <!--      <div class="avatar"></div>-->
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.Username }}</h5>
        <p>{{ curUser.Type }}</p>
        <p>{{ defaultStore }}</p>
      </div>
    </div>
    <div class="routes">
      <ul>
        <li>
          <router-link @click.native="back" :to="{ name : 'dashboard' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-house"/>
            </div>
            Dashboard
          </router-link>
        </li>
        <li>
          <router-link @click.native="back" :to="{ name : 'stock-adjustment' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-sliders"/>
            </div>
            Stock Adjustment
          </router-link>
        </li>

        <li>
          <router-link @click.native="back" :to="{ path : $store.getters.getStockTakeRoute }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-boxes-stacked"/>
            </div>
            Stock Take
          </router-link>
        </li>

        <li>
          <router-link @click.native="back" :to="{ name : 'create-stock-take' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-add"/>
            </div>
            Create Stock Take List
          </router-link>
        </li>

        <li>
          <router-link @click.native="back" :to="{ name : 'select-supplier' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-hand-holding-droplet"/>
            </div>
            Stock Receive
          </router-link>
        </li>


        <li :class="setClass">
          <router-link @click.native="back" :to="{name : 'settings'}">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-gear"/>
            </div>
            Settings

          </router-link>
        </li>


        <li>
          <router-link @click.native="back" :to="{ name : 'poslogin' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-arrow-right-from-bracket"/>
            </div>
            Log out
          </router-link>
        </li>
      </ul>
      <br>
      <div class="last-upd">
        Last Updated : {{ lastUpdated }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "NavigationDrawer",
  props: {
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    auth.isCashierLoggedIn();
    auth.isStoreSelected();
  },
  computed: {
    defaultStore() {
      return this.$store.getters.getDefaultStore.StoreName
    },
    lastUpdated() {
      return this.$store.getters.getLasUpdated
    },
    curUser() {
      return this.$store.getters.getCurrentUser
    },
    setClass() {
      return this.curUser.Type === 'admin' ? '' : 'disabled'
    },
    setMsg() {
      return this.curUser.Type === 'admin' ? '' : 'blocked'
    },
  },
  methods: {
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: scroll;

  .routes {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;

        a {
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          padding-left: 10px;
          text-decoration: none;
          color: #1c1c1c;

          .icon-box {
            width: 35px;
            margin-right: 10px;
            display: flex;
            justify-content: center;

            .icon {
              font-size: 25px;
              color: #34373C;
            }
          }

          &:hover, &.router-link-exact-active {
            background: #f1f1f1;
            color: #42b983;
            font-weight: 700;

            .icon-box {
              .icon {
                color: #42b983;
              }
            }
          }
        }
      }
    }
  }

  .user-avatar {
    display: inline-flex;
    width: 100%;
    /*background-color: #FF9D00;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%230E1E37' stroke-width='66.7' stroke-opacity='0.19' %3E%3Ccircle fill='%23FF9D00' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23ff8c22' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23ff7b34' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23fa6c43' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23f25e4f' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23e7535a' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23d94a63' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%23ca446a' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23b8406f' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23a53d73' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23913c74' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%237d3b72' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%2368396f' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%23543768' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23423360' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%23302f56' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23212b4b' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%2315253F' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");*/

    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #F1A640;
          font-weight: 600;
        }
      }

    }
  }
}

ul {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}
hr {
  border: 1px black solid;
}
</style>
