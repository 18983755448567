import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/icons.js'
import './services/GlobalVariables'
import DrawerLayout from 'vue-drawer-layout'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueToggles from 'vue-toggles';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import VueBarcodeScanner from 'vue-barcode-scanner'

Vue.config.productionTip = false
let options = {
  sensitivity: 60, // default is 100
  requiredAttr: true, // default is false
  controlSequenceKeys: ['NumLock', 'Clear'], // default is null
  callbackAfterTimeout: true // default is false
}

Vue.use(VueBarcodeScanner, options);

Vue.component('loading', Loading);
Vue.component('VueToggles', VueToggles);
Vue.use(VueVirtualScroller)
Vue.use(DrawerLayout);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
