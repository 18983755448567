import {responses} from "@/utils/responses";
import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'stock',
    state: {
        stock: [],
        stockItem: {},
        stockCache: new Map(),
        stockValue : 0.00,
        belowMin : [],
        itemsReceivedRecently : [],

    },
    getters: {
        getStockProducts: (state) => state.stock,
        getAllStockProducts: ({stockCache}) => [...stockCache.values()],
        getStockItem: (state) => state.stockItem,
        getByPlu: (state) => (plu) => state.stockCache.get(plu),
        getStockValue : (state) => state.stockValue,
        getBelowMin : (state) => state.belowMin,
        getReceivedRecently : (state) => state.itemsReceivedRecently,
    },

    mutations: {
        setStock: async (state, payload) => {
            payload.sort((a, b) => {
                return a.Description < b.Description;
            });
            if (payload) {
                await Storage.setStock(payload);
                state.stock = payload.sort((a, b) => {
                    return a.Description < b.Description;
                });
                //state.allStock = payload
            } else {
                await Storage.getAllStock().then(res => {
                    state.stock = res.sort((a, b) => {
                        return a.Description < b.Description;
                    })
                })
            }
        },
        setStockValue : (state, data) => state.stockValue = data,
        setBelowMin : (state, data) => state.belowMin = data,
        setRecentlyReceived : (state, data) => state.itemsReceivedRecently = data,

        setStockSetting: async (state, payload) => {
            if (payload) {
                await Storage.setStockSettings(payload);
            }
        },
        setSearch: (state, payload) => {
            state.stock = payload.sort((a, b) => {
                return a.Description < b.Description;
            })
        },
        setStockItem: (state, payload) => {
            state.stockItem = payload
        },
        editStock: (state, payload) => {
            Storage.editStockItem(payload).catch();
            state.stockCache = payload.map(s => {
                if (s.PLU === payload.PLU) {
                    return payload;
                }
                return s;
            }).reduce((mapAcc, obj) => {
                mapAcc.set(obj.PLU, obj);
                return mapAcc;
            }, new Map())
            state.stock = [...state.stockCache.values()];
            responses.showSuccess('Item updated successfully!');
        },
        addStockItem: (state, payload) => {
            Storage.getAllStock().then(stock => {
                state.stock = state.stock.map(s => {
                    if (s.PLU === payload.PLU) {
                        return payload;
                    }
                    return s;
                })
                return Storage.setStock(state.stock);
            });
        }
    },
    actions: {
        addStockItem: (context, payload) => {
            let store = context.getters.getDefaultStore;
            try {
                delete payload.CreatedUser
            } catch (e) {
                console.error(e)
            }
            return axios.post(`${serverApi}stock/${store.StoreDB}`, payload)
                .then(({data}) => {
                    context.commit('addStockItem', data);
                    context.commit('setStockItem', data);
                    return data;
                }).catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    responses.throwErr(message)
                    throw new Error(message)
                })
        },
        viewStockItem: (context, payload) => {
            return new Promise(resolve => {
                context.commit('setStockItem', payload)
                resolve();
            })
        },
        getStockSetting: (context, plu) => {
            return Storage.getStockSetting(plu)
        },
        getStockValue: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi + `stockvalue/getCurrent/${store.StoreDB}`)
                .then(({data})=>context.commit('setStockValue', data))
        },

        getBelowMin: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi + `stock/getBellowMin/${store.StoreDB}`)
                .then(({data})=>context.commit('setBelowMin', data))
        },

        getReceivedRecently: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseApi + `GRVCurrentLine/getItemsReceivedRecently/${store.StoreDB}`)
                .then(({data})=>context.commit('setRecentlyReceived', data))
        },
        getAllStock: (context) => {
            return Storage.getDefaultStore().then(store => {
                if (!store) return;
                return axios.get(serverApi + `stock/${store.StoreDB}`)
                    .then(({data}) => {
                        const stockSettings = data.map(s => {
                            let set = s.StockSetting
                            if (set) {
                                return {
                                    plu: s.PLU,
                                    cookingLink: set.CookingLink
                                }
                            } else {
                                return {
                                    plu: s.PLU,
                                    cookingLink: false
                                }
                            }
                        })
                        const stock = data.map(s => {
                            delete s.CreatedDate
                            delete s.Image
                            delete s.Price1
                            delete s.Price2
                            delete s.Price3
                            delete s.Price4
                            delete s.Price5
                            delete s.args
                            delete s.where_between
                            delete s.StockSetting
                            return s;
                        })
                        context.commit('setStock', stock);
                        context.commit('setStockSetting', stockSettings);
                    }).catch(({response}) => {
                        let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                        responses.throwErr(message)
                        throw new Error(message)
                    })
            })
        },
        searchStock: (context, query) => {
            context.commit('setCurrentCategory', query.category)
            context.commit('setSubCategory', query.subCategory)
            context.commit('setSubCategory2', query.sub2Category)
            return Storage.searchStock(query).then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },
        searchDescription: (context, query) => {
            context.commit('setCurrentCategory', query.category)
            context.commit('setSubCategory', query.subCategory)
            context.commit('setSubCategory2', query.sub2Category)
            return Storage.searchStockDesc(query).then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },

        searchDepartment: async (context, query) => {
            query.description = ' ';
            return Storage.searchStockDesc(query).then((value => value));
        },
        getFastLine: (context, query) => {
            return Storage.getFastLine().then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },
        adjustStock: (context, item) => {
            return Swal.fire({
                allowOutsideClick: false,
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: `Adjust Qty`,
                html: `<h6 >Description : ${item.Description}</h6>
               <h6 >Current QTY : ${item.QTY}</h6>
               <select name="adjustment-type" class="swal2-input" id="adjustment-type" style="max-width: 70%">
               <option selected value="1">Remove Stock(Damaged)</option>
               <option value="0">Add Stock Qty(Miscount)</option>
               </select>
               <input type="number" step="0.001" id="qty" class="swal2-input" placeholder="Adjustment Qty" style="max-width: 70%">
               
               <input type="text" id="adj-reason" class="swal2-input" placeholder="Reason" style="max-width: 70%">`,
                confirmButtonText: 'Adjust Qty',
                focusConfirm: false,
                preConfirm: async () => {
                    let new_qty = Swal.getPopup().querySelector('#qty').value
                    if (new_qty === null || new_qty === undefined || new_qty === '') {
                        return Swal.showValidationMessage(`Please enter a valid Adjustment Quantity`)
                    }

                    let reason = Swal.getPopup().querySelector('#adj-reason').value
                    if (reason === null || reason === undefined || reason === '') {
                        return Swal.showValidationMessage(`Please enter a valid Reason`)
                    }

                    let type = Swal.getPopup().querySelector('#adjustment-type').value
                    let user = await Storage.getCurrentUser();
                    let data = {
                        user: user.Username,
                        pc: 'mobile',
                        plu: item.PLU,
                        reason: reason,
                        qty: parseFloat(new_qty),
                        adjustmentType: parseFloat(type),
                    };
                    return context.dispatch('processAdjustment', data)
                        .then(() => data)
                        .catch(err => Swal.showValidationMessage(err))
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire(
                        'Done!',
                        `${item.Description} quantity has been adjusted successfully.`,
                        'success'
                    );
                }
            })
        },
        uploadAProducePic: (context, data) => {
            console.log(data)
        },

        processAdjustment: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(serverApi + `stock/${store.StoreDB}/adjustment`, payload)
                .then(() => context.dispatch('getAllStock'))
                .catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    throw new Error(message)
                })
        }
    }
}
