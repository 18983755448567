import axios from "axios"
import {Storage} from "@/services/Storage";
import {responses} from "@/utils/responses";

export default {
    name: 'stockCategories',
    state: {
        currentCategory: '',
        subCategory: '',
        sub2Category: '',
        categories: [],
    },
    getters: {
        getCategories: (state) => state.categories,
        getSubDepartments: (state) => (dep) => {
            let data = state.categories.find(main => main.name.toLowerCase() === dep.toLowerCase())
            if (data) {
                return data.children
            } else {
                return []
            }
        },
        getSub2Departments: (state, getters) => (dep, sub) => {
            let data = getters.getSubDepartments(dep).find(sub2 => sub2.name.toLowerCase() === sub.toLowerCase())
            if (data) {
                return data.children
            } else {
                return []
            }
        },
        getCurrentCategory: (state) => state.currentCategory,
        getSubCategory: (state) => state.subCategory,
        getSubCategory2: (state) => state.sub2Category,
    },

    mutations: {
        setCategories: async (state, payload) => {
            if (!payload) {
                payload = await Storage.getCategories();
            } else {
                await Storage.setCategories(payload);
            }
            state.categories = payload
        },
        setCurrentCategory: (state, payload) => state.currentCategory = payload,
        setSubCategory: (state, payload) => state.subCategory = payload,
        setSubCategory2: (state, payload) => state.sub2Category = payload,
    },
    actions: {
        getCategories: async (context) => {
            context.commit('setCategories')
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(serverApi + `category/${store.StoreDB}/getAsTree`)
                .then(({data}) => {
                    data = data.filter(category=>category.name.trim() !== '')
                    context.commit('setCategories', data)
                }).catch(({response}) => {
                    let message = response.data.message?response.data.message:response.data.split('\n', 1)[0];
                    responses.throwErr(message)
                    throw new Error(message)
                })
        },
        createMainCategory: (context, category) => {
            return context.dispatch('createUpdateMain', {
                id: null,
                name: category,
            }).then(res => res)
        },

        editMainCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: payload.id,
                name: payload.name,
            }).then(res => res)
        },

        createUpdateMain: (context, payload) => {
            return Storage.getDefaultStore().then(store => {
                return axios.post(franchiseApi + `Category/${store.StoreDB}`, {
                    id: payload.id,
                    main: payload.name,
                    sub: payload.sub ? payload.sub : ' ',
                    sub2: payload.sub2 ? payload.sub2 : ' ',
                }).then((res) => {
                    if (res.status === 200) {
                        return payload.name
                    } else {
                        return {
                            error: res.status,
                            message: res.data
                        };
                    }
                }).catch(({response}) => {
                    let message = response.data.message?response.data.message:response.data.split('\n', 1)[0];
                    responses.throwErr(message)
                    throw new Error(message)
                }).finally(() => context.dispatch('getCategories'))
            })
        },

        editSubCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: payload.id,
                name: payload.mainCat,
                sub: payload.sub
            }).then(res => res)
        },

        createSubCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: null,
                name: payload.main,
                sub: payload.sub
            }).then(res => res)
        }

    }
}
