import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'stock-received',
    state: {
        itemsToReceive: []
    },
    getters: {
        getItemsToReceive: (state) => state.itemsToReceive
    },

    mutations: {
        setItemsToReceive : (state, data) => state.itemsToReceive = data,
        addToReceivedItems: (state, data) => state.itemsToReceive.push(data),
        clearGrvData: (state) => {
            state.itemsToReceive = [];
            state.selectedSupplierCode = ''
        },
        removeFromGRV: (state , item) => {
            let plu = item.PLU;
            state.itemsToReceive = state.itemsToReceive
                .filter(stockItem => stockItem.PLU !== plu);
        }
    },
    actions: {
        createGRV: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser()
            let data = {
                supplier: context.getters.getSelectedSupplierCode === 'AUTO-CASH'? null :
                    context.getters.getSelectedSupplierCode,
                items: payload.items,
                user: user.Username,
                FromAccount: payload.FromAccount,
                pc: 'mobile'
            }
            return axios.get(franchiseApi + `Supplier/getAll/${store.StoreDB}`)
                .then(() => {
                    console.log(data);
                    context.commit('clearGrvData')
                })
                .catch(({response}) => {
                    let message = response.data.message ? response.data.message : response.data.split('\n', 1)[0];
                    throw new Error(message)
                })
        }
    }
}
